.login-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vh;
  height: 100vh;
  fill-opacity: 0.1 black;
  transition: transform 0.3s ease-out;
  /* opacity: 0.5; */
}
.login-box {
  /* position: absolute; */
  /* opacity: 1; */
  width: 400px;
  height: 480px;
  /* border: 1px black solid; */
  margin: auto auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  box-shadow: 0 0 20px grey;
}

.link-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  height: 50px;
  /* height: max-content; */
  border: 1px solid grey;
  margin: 1%;
  border-radius: 10px;
  font-size: larger;
}
.img {
  height: 40px;
  width: 40px;
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1% ;
  /* gap: 1%; */
  /* word-wrap: none; */
  /* flex-wrap: nowrap; */ 
}

a:hover {
  text-decoration: none;
  box-shadow: none;
}

.input {
  width: 250px;
  height: 30px;
  border: 1px black;

}
