@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --blue: #04439f;
  --white: #ffffff;
  --grey: #e1e1e3;
  --green-900: #00631f;
    --green-800: #00752e;
    --green-700: #007f36;
    --green-600: #008a3e;
    --green-500: #009245;
    --green-400: #26a261;
    --green-350: #7fd7a4;
    --green-300: #4db37d;
    --green-250: #65b168;
    --green-200: #80c9a2;
    --green-100: #b3dec7;
    --green-70: #37833b;
    --green-60: #43a047;
    --green-50: #e0f2e9;
    --green-30: #d2f7f2;
    --green-20: #cbe5cc;
    --green-A100: #3f6864;
    --green-A70: #0bb09f;
    --grey-B1000: #1e2124;
    --grey-B500: #1a202c;
    --grey-B100: #8e9091;
    --grey-1000: #19191d;
    --grey-950: #282b30;
    --grey-900: #212121;
    --grey-800: #424242;
    --grey-750: #424549;
    --grey-700: #616161;
    --grey-600: #757575;
    --grey-500: #9e9e9e;
    --grey-400: #bdbdbd;
    --grey-300: #e0e0e0;
    --grey-200: #eeeeee;
    --grey-100: #f5f5f5;
    --grey-90: #292a31;
    --grey-80: #3a3a44;
    --grey-70: #4a4b57;
    --grey-60: #5a5b6a;
    --grey-50: #fafafa;
    --grey-40: #9696a0;
    --grey-30: #b4b4bb;
    --grey-20: #d2d2d6;
    --grey-10: #e1e1e3;
    --grey-5: #f0f0f1;
    --grey-0: #f9f9fa;
    --grey-A800: #1e2124;
    --grey-A700: #000000;
    --grey-A650: #626466;
    --grey-A350: #bbbcbd;
    --grey-A300: #9696a0;
    --grey-A600: #19191d;
    --grey-A500: #787885;
    --grey-A450: #d2d3d3;
    --grey-A400: #343434;
    --grey-A250: #f1f2f2;
    --grey-A200: #d0d0d0;
    --grey-A150: #d9d9d9;
    --grey-A100: #ffffff;
    --orange-1000: #fb813e;
    --orange-900: #f6430f;
    --orange-850: #ff6060;
    --orange-800: #f85618;
    --orange-700: #f9601e;
    --orange-600: #f96b24;
    --orange-500: #fa7328;
    --orange-550: #fb813e;
    --orange-400: #fb8848;
    --orange-300: #fc9d69;
    --orange-200: #fdb994;
    --orange-100: #fed5bf;
    --orange-90: #af511c;
    --orange-80: #c85c20;
    --orange-70: #e16824;
    --orange-60: #fa7328;
    --orange-50: #feeee5;
    --orange-40: #fb8f53;
    --orange-30: #fc9d69;
    --orange-20: #fdc7a9;
    --orange-10: #fed5bf;
    --orange-5: #fff1ea;
    --orange-0: #faf7f2;
    --orange-A700: #fbab7e;
    --orange-A600: #fca16e;
    --orange-A500: #fff9f5;
    --red-900: #b30108;
    --red-800: #be0110;
    --red-700: #c40114;
    --red-600: #cb0218;
    --red-500: #d0021b;
    --red-400: #d7283d;
    --red-300: #de4e5f;
    --red-200: #e8818d;
    --red-100: #f1b3bb;
    --red-60: #f44336;
    --red-50: #f9e1e4;
    --red-A600: #f6655a;
    --purple-900: #3400e2;
    --purple-800: #4600e7;
    --purple-700: #5000e9;
    --purple-600: #5a00ec;
    --purple-500: #6200ee;
    --purple-400: #7a26f1;
    --purple-300: #914df3;
    --purple-200: #b180f7;
    --purple-100: #d0b3fa;
    --purple-70: #8b7fd7;
    --purple-60: #2f154c;
    --purple-50: #421d68;
    --purple-40: #7533b7;
    --purple-30: #dee7fc;
    --purple-10: #faf6ff;
    --purple-A100: #82455e;
    --purple-LIGHT1300: #fafafd;
    --purple-LIGHT500: #5341a0;
    --purple-LIGHT300: #3d3465;
    --pink-900: #8a004e;
    --pink-800: #b00054;
    --pink-700: #c50058;
    --pink-600: #db005c;
    --pink-500: #ec005e;
    --pink-400: #ee0978;
    --pink-300: #f14d91;
    --pink-250: #d781ac;
    --pink-200: #f484b1;
    --pink-100: #f8b5d0;
    --pink-60: #f1639e;
    --pink-50: #fce2ec;
    --pink-30: #fed7e7;
    --blue-1300: #fafbfe;
    --blue-1100: #30374d;
    --blue-1000: #d5dff9;
    --blue-700: #425996;
    --blue-600: #5780e9;
    --blue-500: #112c67;
    --blue-400: #e6f0ff;
    --blue-100: #435377;
    --blue-70: #496ec5;
    --blue-60: #557cd9;
    --blue-50: #04439f;
    --blue-40: #0a6dff;
    --blue-10: #b6d3ff;
    --blue-5: #f3f8ff;
    --blue-A100: #435377;
    --blue-A200: #f5f7fe;
    --blue-A300: #5780e9;
    --violet-60: #1d195e;
    --yellow-1200: #fffbf4;
    --yellow-700: #d1941a;
    --yellow-500: #ffc147;
    --yellow-100: #f3a805;
    --yellow-70: #eaa30a;
    --yellow-50: #fff8ea;
    --yellow-40: #d7a07f;
}