.Box {
  padding: 40px 50px;
  background: #0a2541;
  /* position: absolute; */
  bottom: 0;

  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
}
.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
}

.Column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 50px;
}

.Row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
/* .logo{
  background: url('../assests/new-logo.svg') no-repeat fixed;
} */

a {
  /* margin-bottom: 8px; */
  color: #eee;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  line-height: 200%;
  font-weight: 500;
  position: relative;

  &:hover {
    color: orangered;
    box-shadow: 0 1px 0 orangered;
    transition: 200ms ease-in;
    width: fit-content;
  }
}

/* a:hover hr{
    display: block;
    color: orangered;
    
  height: 1px solid ;
  width: auto;
  position: absolute;
} */

.Heading {
  margin-bottom: 20px;
  color: #fff;
  font-weight: bolder;
  font-size: 16px;
  line-height: 20px;
}
.contact {
  color: #fff;
  margin-bottom: 12px;
  font-size: 18px;
}
