.box{
    display: flex;
    width: 630px;
    /* width: max-content; */
    height: max-content;
    background: #ffff;
   
}

.left, .right{
    width: 50%;
    /* height: 350px; */
    
    display: flex;
    flex-direction: column;
    box-shadow: 1px 0 0 grey;
}
.right{
    width: 60%;
    position: relative;
}

.title{
    font-size: medium;
    text-align: left;
}
.sub{
    font-size: small;
    line-height: normal ;
    color: grey;
    width: 100%;
    text-align: left;
}


.coursebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 50px;
    border: 1px solid orangered;
    border-radius: 10px;
    font-weight: 400;
    padding: 1%;
    
}

a{
    color: black;
    text-decoration: none;
    font-size: small;
    line-height: normal;
    
}

.courses{
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin:0 1%;
    position: relative;
}
.courses:hover{
    box-shadow: -5px 0 0 orange;
    background-color: rgba(238, 230, 230, 0.445);
    
}

.courses .coursesexpand{
    display: none;
    position: absolute;
    width: 250px;
    height: 250px;
    left: 326px;
    /* top: 0%; */
    background-color: white;
    transform: translate(1%, 0%);
    transition: 5s ease-out;
    margin: 0 1%;
    border-radius: 10px;
    box-shadow: 0 0 20px #65657b;
}

.courses:hover .coursesexpand{
    display: block;
    cursor: pointer;
    
}
.coursesList{
    display: flex;
    margin:0 2%;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1%;

 &:hover{
    background-color: rgba(238, 230, 230, 0.445);
    box-shadow: -3px 0 0 grey;
 }   
}

.c-title{
    color:aqua;
    font-size: large;
    font-weight: bold;
}