/* ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  
  text-decoration: none;
} */

.navbar {
  display: flex;
  width: 100%;
  background: linear-gradient(
      97.82deg,
      rgba(32, 5, 84, 0.2) 5.54%,
      rgba(31, 21, 94, 0.2) 98.35%
    ),
    #0a2541;
  box-shadow: 0 12px 24px #3a3a4414, 0 24px 48px #5a5b6a14;
  /* border-radius: 8px; */
  scroll-behavior: smooth;
  justify-content: center;
}

.navbar_link {
  text-decoration: none;
  color: #fff;
  /* position: relative; */

}
.navbar_link:hover{
  box-shadow: none;
}

.header-container {
  display: flex;
  justify-content: space-between;
  height: 55px;
  width: 100%;
  /* max-width: 1152px; */
  align-items: center;
  margin: auto;
  /* z-index: 99; */
}

.header-content {
  display: flex;
  align-items: center;
}

.main-logo {
  padding: 0 20px;
  width: fit-content;
  mix-blend-mode: screen;
}
img {
  width: 75px;
  height: 28px;
}

.menu-items {
  display: flex;
  font-weight: 600;
}

.menu-item {
  height: 55px;
  /* width: max-content; */
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: relative;
}
.menu-item:hover {
  box-shadow: 0 5px 0 orange;
}

.passiveLink {
  text-decoration: none;
  color: var(--grey -20);
}

.login-btn {
  padding: 8px 20px;
  margin: 5px;
  font-size: 13px;
  letter-spacing: 0.3px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  outline: none;
  line-height: 22px;
  min-width: 64px;
  overflow: visible;
  text-decoration: none;
  background-color: #fed3bc;
  color: #f9601e;
}

.enroll-btn {
  margin: 5px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.3px;
  width: fit-content;
  background-color: orangered;
  border-radius: 6px;
  border: none;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.menu-item {
  position: relative;
}

.menu-item .inner-div {
  position: absolute;
  display: none;
  top: 170px;
  left: 20%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10%;
  transform: translate(-50%, -50%);
}
.menu-item:hover .inner-div {
  display: block;
  box-shadow: 0 0 20px #65657b;
  transition: 5s linear;
}


a:hover{
  text-decoration: none;
  box-shadow: none;
}