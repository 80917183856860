

*{
  font-family: Mulish,sans-serif;
}
.nav-home{
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
