.flex {
  display: flex;
  justify-content: center;
  width: 100%;
  background: url('../assests/group.svg') no-repeat bottom 0 left 0,
    linear-gradient(90.02deg, #343b89 -1.1%, #4d4899 99.97%);
  margin: 1% 0;
}

.why {
  margin: 3%;
  display: flex;
}

.why-left {
  display: flex;
  flex-direction: column;
  max-width: 466px;
}

.left1 {
  width: 136px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding-top: 10px;
  text-align: center;
}

.left2 {
  font: 700 32px/42px Mulish, Roboto, Helvetica Neue, sans-serif;
  padding-top: 32px;
  padding-bottom: 16px;
}

.left3 {
  font: 600 16px/24px Mulish, Roboto, Helvetica Neue, sans-serif;
}

.why-right {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

/* .hex-content {
  position: relative;
} */

.hex {
 
  display: flex;
  flex-direction: column;
  color: var(--grey-A100);
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  background: url('../assests/hex-box.svg') no-repeat ;
  height: 150px;
  width: 140px;
  margin: 1%;
  display: flex;
  justify-content: center;  
}

.hex-box1{
    display: flex;
    align-items: flex-start;
}

.hex-box2{
    display: flex;
    align-items: flex-end;
    margin-right: 0%;
}
.hex1 {
  top: 18%;
}
.hex2{
    top: 18%;
    right : 20%;
}
/* .hex3{
    bottom: 0;
    right: 0;
}
.hex3{

} */

.head {
  font-weight: 800;
  font-size: x-large;
}

.subhead {
  font-weight: 500;
  font-size: small;
}
